












import { Component } from 'vue-property-decorator';
import { FormAction } from '@/app/core/types';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ApartmentDto from '../dto/apartment.dto';
import ApartmentForm from '../components/apartment-form.vue';
import apartmentsService from '../services/apartments.service';

@Component({
  metaInfo(this: NewApartment) {
    return { title: this.$t('pageTitle.newApartment').toString() };
  },
  components: {
    ApartmentForm,
  },
})
export default class NewApartment extends HandlesErrorMixin {
  private loading = false;
  private action = FormAction.Create;

  async createApartment(dto: ApartmentDto) {
    this.loading = true;

    try {
      const response = await apartmentsService.createApartment(dto);
      const { id } = response.data;

      this.$router.push(`/apartments/details/${id}`);
      this.$notify.success(this.$t('success.apartmentCreated').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
